import React from "react";
import "./result.scss";
import { connect } from "react-redux";
import { updateUserData } from "../../redux/action/user";
import ResultHeader from "../../component/result/resultHeader";
import ResultBody from "../../component/result/resultBody";
import Footer from "../../component/footer";
import { useEffect } from "react";
import { useNavigate } from "react-router";
// import { socket } from "../../constants/config";
// import { connectSocket, disconnectSocket } from "../../common";

const Result = (props) => {
  const navigate = useNavigate();

  // console.log("soooocket", socket)

  // socket.on("welcome", (msg) => {
  //   console.log("mssg", msg);
  // });

  // socket.emit("goalsData", {
  //   userID: localStorage.getItem("userKey"),
  //   assessmentID: localStorage.getItem("assesmentKey"),
  // });

  // const handleVisibilityChange = () => {
  //   if (document.visibilityState === "visible") {
  //     connectSocket();
  //   } else {
  //     // disconnectSocket();
  //   }
  // };

  useEffect(() => {
    // handleVisibilityChange();
    // document.addEventListener("visibilitychange", handleVisibilityChange);

    if (!localStorage.getItem("userKey")) {
      navigate("/");
    }
    localStorage.removeItem("review");

    // socket.on("progressUpdate", (data) => {
    //   console.log("Progress updated:", data);
    //   // Update the UI with the received progress
    // });

    // const emitGoalsData = () => {
    //   socket.emit('goalsData', {
    //     userID: localStorage.getItem('userKey'),
    //     assessmentID: localStorage.getItem('assesmentKey'),
    //   });
    // };

    // emitGoalsData(); // Emit the event on component mount

    // return () => {
    //   document.removeEventListener("visibilitychange", handleVisibilityChange);
    //   disconnectSocket();
    // };
  }, []);

  return (
    <>
      <ResultHeader />
      <div>
        <ResultBody />
      </div>
      <Footer />
    </>
  );
};

Result.propTypes = {};
Result.defaultProps = {};

const actionCreators = {
  updateUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(Result);
