import { SET_USER_DATA } from "../../constants/ActionTypes";

import enLanguage from "../../assets/language/en.json";

const INITIAL_STATE = {
  language: enLanguage,

  signupForm: {
    firstName: "",
    lastName: "",
    emailaddress: "",
    mobileNumber: "",
    countryCode: "+1",
    hostName: "",
    // marketingInfo: '',
  },
  loginForm: {
    emailaddress: "",
    countryCode: "+1",
    mobileNumber: "",
    parsedMobileNumber: "",
  },

  status: "signup",
  isNewUser: false,

  firstName: "",
  lastName: "",
  emailaddress: "",
  mobileNumber: "",
  countryCode: "+1",
  hostName: "",
  programPurchased: [],
  subscription: null,
  customerId: null,
  // marketingInfo: '',

  _id: "",
  surveyId: "",

  height: "",
  heightunit: "Inches",
  weight: "",
  weightunit: "Pounds",

  zipcode: "",

  hash: "",
  age: "",
  gender: "",
  isLoggedinModal: false,

  userFinalScore: 0,
  questionList: [],
  totalDetermine: [],
  surveyIdList: [],
  programList: [],
  paymentHistoryList: [],
  feedbackQuestionList: [],

  error: {
    errormsg: "",
  },

  isLoading: false,

  userInfo: {
    firstName: "",
    lastName: "",
    emailaddress: "",
    mobileNumber: "",
    countryCode: "+1",
    hostName: "",
    _id: "",
    isSurveyCompleted: "",
    height: "",
    heightunit: "Inches",
    weight: "",
    weightunit: "Pounds",
    zipcode: "",
    hash: "",
    age: "",
    gender: "",
  },

  paymentCheckout: {
    url: "",
  },
};

const user = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        ...action.content,
      };
    default:
      return state;
  }
};

export default user;
