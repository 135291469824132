import React, { useEffect, useState } from "react";
import "./login.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserData, updateUserData } from "../../redux/action/user";
import LoginSection from "../../component/loginSection";
import OtpSection from "../../component/otpSection";
import SignUpSection from "../../component/signUpSection";
import leftImage from "../../assets/images/left-img.png";
import leftInnerImage from "../../assets/images/left-inner-img.png";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Footer from "../../component/footer";
import Header from "../../component/header";
import bgImage from "../../assets/images/bg-top-repeat.png";
import CenterLoading from "../../component/core/CenterLoading";

const Login = (props) => {
  const navigate = useNavigate();
  const [isLoginPage, setIsLoginPage] = useState(false);
  const [page, setPage] = useState("signup");

  useEffect(() => {
    if (localStorage.getItem("userKey")) {
      props.getUserData(localStorage.getItem("userKey"), navigate);
    } else {
      props.updateUserData({
        ...props.user,
        isNewUser: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page === "login") {
      setIsLoginPage(true);
    } else if (page === "signup") {
      setIsLoginPage(false);
    }
  }, [page]);

  const changePage = (page) => {
    setPage(page);
  };

  return (
    <>
      <Header page={page}/>
      <div className="ab-bg-image">
        <img src={bgImage} alt={bgImage}></img>
      </div>
      <div className="login-container">
        <div className="flex-center">
          <div className="out-box-wrap">
            <Card
              sx={{
                display: "flex",
                borderRadius: "15px",
                backgroundColor: "transparent",
                maxWidth: "90%",
                width: "910px",
              }}
            >
              <Box className="left-side-box" /* sx={{ width: "50%" }} */>
                <div className="text-center company__info">
                  {/* <img
                    src={leftImage}
                    width="100%"
                    height="100%"
                    alt="Company Logo"
                    className="company-logo"
                  /> */}
                  <div className="ab-inner-image-wrap">
                    <img src={leftInnerImage} alt="Company Logo" />
                  </div>
                </div>
              </Box>
              <Box className="right-side-box" /* sx={{ width: "55%" }} */>
                {page === "login" ? (
                  <LoginSection page={page} changePage={changePage} />
                ) : page === "otp" ? (
                  <OtpSection
                    isLoginPage={isLoginPage}
                    page={page}
                    changePage={changePage}
                  />
                ) : page === "signup" ? (
                  <SignUpSection page={page} changePage={changePage} />
                ) : (
                  <>
                    <p className="subTitle">Oops</p>
                  </>
                )}
              </Box>
            </Card>
          </div>
        </div>
      </div>
      <Footer />

      <CenterLoading open={props.user.isLoading} />
    </>
  );
};

Login.propTypes = {};
Login.defaultProps = {};

const actionCreators = {
  getUserData,
  updateUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(Login);
