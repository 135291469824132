import React, { Fragment, useEffect, useState } from "react";
import "./plans.scss";
import ResultHeader from "../../component/result/resultHeader";
// import ButtonInput from "../../atoms/button";
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Box,
  Button,
  CardMedia,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import closex from "../../assets/images/x.png";
import { connect } from "react-redux";
import Loading from "../../component/Loader";
import SnackBar from "../../atoms/snackBar";
import { getProgramList, paymentCheckoutURL } from "../../redux/action/user";
import { useNavigate } from "react-router";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import founder from "../../assets/images/founders_image.jpg";
import "react-quill/dist/quill.snow.css";

const PlanSubscription = (props) => {
  const navigate = useNavigate();
  const [desc, setDesc] = useState("");
  const [descModal, setDescModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [selectedPgrm, setSelectedPgrm] = useState({});

  const subscriptionList =
    props.user.programList &&
    props.user.programList.filter((item) => item.isSubscription === true);

  const programsList =
    props.user.programList &&
    props.user.programList.filter((item) => !item.isSubscription);

  // Handle the expansion of the accordion
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Style for the modal container
  const modalStyle = {
    overflow: "hidden",
    position: "relative",
    // height: "80vh",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxHeight: "90vh",
    backgroundColor: "white",
    boxShadow: 24,
    padding: "20px",
    // overflowY: "auto", // Makes the content scrollable
    borderRadius: "8px",
    ".close-pop-wrap": {
      top: "0px",
      right: "0px",
      position: "sticky",
      padding: "10px",
      textAlign: "right",
      img: {
        width: "24px",
        height: "24px",
      },
    },
  };

  // const [selectedPlan, setSelectedPlan] = useState(null); // State to track selected plan

  // const handleSelectPlan = (plan) => {
  //   if (selectedPlan?.ID === plan.ID) {
  //     setSelectedPlan(null); // If the same plan is clicked, collapse it
  //   } else {
  //     setSelectedPlan(plan); // Set the selected plan to show details on the right side
  //   }
  // };

  useEffect(() => {
    if (!localStorage.getItem("userKey")) {
      navigate("/");
    }
    props.getProgramList();
  }, []);

  useEffect(() => {
    if (props.user?.error.errormsg) {
      setErrorMessage(props.user?.error.errormsg);
    }
  }, [props.user?.error.errormsg]);

  useEffect(() => {
    if (errorMessage) {
      setOpenSnackBar(true);
    }
  }, [errorMessage]);

  const isPlanPurchased = (plan) => {
    const isProgramPurchased =
      props.user.programPurchased &&
      props.user.programPurchased?.some(
        (purchase) => purchase.programSubscriptionId === plan.ID
      );

    if (plan.isSubscription) {
      return (
        (props.user.subscription &&
          props.user.subscription.isSubscription &&
          props.user.subscription.programSubscriptionId == plan.ID &&
          plan.isSubscription) ||
        isProgramPurchased
      );
    }
    return isProgramPurchased;
  };

  const handlePayment = async (ele) => {
    // const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const body = {
      ID: ele.ID,
    };
    const url = await props.paymentCheckoutURL(body);
  };

  const handleSeeMore = (description) => {
    setDesc(description);
    setDescModal(true);
  };

  const formatDuration = (duration, interval) => {
    const formattedInterval = duration > 1 ? `${interval}s` : interval;
    return `${duration} ${formattedInterval}`;
  };

  const cards = (ele) => (
    <Grid item xs={12} sm={6} md={4} key={ele.ID}>
      <div className="plan-block">
        <div className="plan-title">
          <Typography variant="h5" className="white-color avenir-bold">
            {ele.isSubscription ? "Become A Member" : "PROGRAM"}
          </Typography>
        </div>
        <div className="plan-detail" style={{ textAlign: "center" }}>
          <Typography variant="h6" className="plan-head">
            {ele.name}
          </Typography>
          <div className="ImgWrap">
            <CardMedia
              component="img"
              alt={ele.name}
              // height="140"
              image={ele.imageUrl}
              title={ele.name}
            />
          </div>

          <Typography className="plan-desc">
            {ele.description && ele.description.length > 120 ? (
              <>
                {ele.description.slice(0, 120)}...
                <span
                  className="see-more"
                  onClick={() => {
                    handleSeeMore(ele.description);
                    setSelectedPgrm(ele);
                  }}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  See more
                </span>
              </>
            ) : (
              ele.description
            )}
          </Typography>
          <Typography
            className="plan-desc"
            style={{ textDecoration: "underline" }}
            onClick={() => {
              handleSeeMore(ele.description);
              setSelectedPgrm(ele);
            }}
          >
            Details
          </Typography>
        </div>
        <div className="BuyNowOption">
          <div className="TextWrap">
            <Typography>
              Validity:{" "}
              {ele.duration && formatDuration(ele.duration, ele.interval)}
            </Typography>
            <Typography>
              Price:{" "}
              {`$${ele.amount} ${ele.currency && ele.currency.toUpperCase()}`}
            </Typography>
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePayment(ele)}
            className="buy-now-button"
            disabled={isPlanPurchased(ele)}
            sx={{
              backgroundColor: isPlanPurchased(ele)
                ? "grey !important"
                : "#e29c2e !important",
              pointerEvents: isPlanPurchased(ele) ? "none" : "auto",
            }}
          >
            {isPlanPurchased(ele)
              ? ele.isSubscription
                ? "Active"
                : "Purchased"
              : "Buy Now"}
          </Button>
        </div>
      </div>
    </Grid>
  );

  return (
    <>
      <ResultHeader />
      {props.user.isLoading ? (
        <Loading />
      ) : (
        <div className="plans">
          {subscriptionList && (
            <Fragment>
              <Typography
                sx={{
                  padding: "10px",
                  color: "#fff",
                  backgroundColor: "#6e6ed1",
                  borderRadius: "10px",
                  width: "97%"
                }}
              >
                Subscriptions
              </Typography>
              <Grid container spacing={2} marginTop={1} marginBottom={4}>
                {subscriptionList.map((ele, index) => cards(ele, index))}
              </Grid>
            </Fragment>
          )}
          {programsList && (
            <Fragment>
              <Typography
                sx={{
                  padding: "10px",
                  color: "#fff",
                  backgroundColor: "#6e6ed1",
                  borderRadius: "10px",
                  width: "97%"
                }}
              >
                Programs
              </Typography>
              <Grid container spacing={2} marginTop={1}>
                {programsList.map((ele, index) => cards(ele, index))}
              </Grid>
            </Fragment>
          )}
          {subscriptionList &&
            subscriptionList.length < 1 &&
            programsList &&
            programsList.length < 1 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="60vh"
                sx={{ textAlign: "center", color: "white", flex: "auto" }} // MUI sx prop for styling
              >
                <Typography variant="h6">No Plan is available</Typography>
              </Box>
            )}
        </div>
      )}
      <Modal open={descModal} onClose={() => setDescModal(false)}>
        <Box sx={modalStyle}>
          <div className="close-pop-wrap">
            <img
              src={closex}
              alt={closex}
              onClick={() => setDescModal(false)}
            ></img>
          </div>
          <div>
            {selectedPgrm && (
              <HtmlContent rawHtmlContent={selectedPgrm.summary} />
            )}
          </div>
        </Box>
      </Modal>

      <SnackBar
        open={openSnackBar}
        message={errorMessage}
        handleCloseSnackBar={() => {
          setOpenSnackBar(false);
          setErrorMessage("");
        }}
      />
    </>
  );
};

PlanSubscription.propTypes = {};
PlanSubscription.defaultProps = {};

const actionCreators = {
  paymentCheckoutURL,
  getProgramList,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(PlanSubscription);

const HtmlContent = ({ rawHtmlContent }) => {
  // Decode the raw HTML content
  const decodedHtml = decodeHtmlEntities(rawHtmlContent);

  return <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />;
};

export function decodeHtmlEntities(text) {
  const doc = new DOMParser().parseFromString(text, "text/html");
  return doc.documentElement.textContent || doc.documentElement.innerText;
}

// <Grid container spacing={2}>
//   {/* Left Side - Accordion with Plan Titles */}
//   <Grid item xs={12} sm={4} md={3}>
//     <div className="plans-left">
//       {props.user.programList && props.user.programList.length > 0 ? (
//         props.user.programList.map((ele, index) => (
//           <Accordion
//             key={ele.ID}
//             expanded={selectedPlan?.ID === ele.ID} // Expand if this plan is selected
//             onChange={() => handleSelectPlan(ele)} // Toggle accordion when clicked
//           >
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls={`panel${ele.ID}-content`}
//               id={`panel${ele.ID}-header`}
//             >
//               <Typography variant="h6">
//                 {ele.isSubscription ? "SUBSCRIPTION" : "PROGRAM"} -{" "}
//                 {ele.name}
//               </Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Typography variant="body2">
//                 {ele.description && ele.description.length > 120 ? (
//                   <>
//                     {ele.description.slice(0, 120)}...
//                     <span
//                       className="see-more"
//                       onClick={() => handleSeeMore(ele.description)}
//                       style={{ cursor: "pointer", color: "blue" }}
//                     >
//                       See more
//                     </span>
//                   </>
//                 ) : (
//                   ele.description
//                 )}
//               </Typography>
//             </AccordionDetails>
//           </Accordion>
//         ))
//       ) : (
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           height="60vh"
//           sx={{ textAlign: "center", color: "white", flex: "auto" }} // MUI sx prop for styling
//         >
//           <Typography variant="h6">No Plan is available</Typography>
//         </Box>
//       )}
//     </div>
//   </Grid>

//   {/* Right Side - Full Plan Details */}
//   <Grid item xs={12} sm={8} md={9}>
//     <div className="plan-details-right">
//       {selectedPlan ? (
//         <div className="plan-block">
//           <div className="plan-title">
//             <Typography
//               variant="h5"
//               className="white-color avenir-bold"
//             >
//               {selectedPlan.isSubscription ? "SUBSCRIPTION" : "PROGRAM"}
//             </Typography>
//           </div>
//           <div className="plan-detail" style={{ textAlign: "center" }}>
//             <Typography variant="h6" className="plan-head">
//               {selectedPlan.name}
//             </Typography>
//             <div className="ImgWrap">
//               <CardMedia
//                 component="img"
//                 alt={selectedPlan.name}
//                 image={selectedPlan.imageUrl}
//                 title={selectedPlan.name}
//                 sx={{ height: "50%" }}
//               />
//             </div>
//             <Typography className="plan-desc">
//               {selectedPlan.description}
//             </Typography>
//           </div>
//           <div className="BuyNowOption">
//             <div className="TextWrap">
//               <Typography>
//                 Validity:{" "}
//                 {selectedPlan.duration &&
//                   formatDuration(
//                     selectedPlan.duration,
//                     selectedPlan.interval
//                   )}
//               </Typography>
//               <Typography>
//                 Price:{" "}
//                 {`${selectedPlan.amount} ${
//                   selectedPlan.currency &&
//                   selectedPlan.currency.toUpperCase()
//                 }`}
//               </Typography>
//             </div>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => handlePayment(selectedPlan)}
//               className="buy-now-button"
//             >
//               Buy Now
//             </Button>
//           </div>
//         </div>
//       ) : (
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           height="60vh"
//           sx={{ textAlign: "center", color: "white", flex: "auto" }}
//         >
//           <Typography variant="h6">
//             Select a plan to see details
//           </Typography>
//         </Box>
//       )}
//     </div>
//   </Grid>
// </Grid>

/* <Modal open={descModal} onClose={() => setDescModal(false)}>
        <Box sx={modalStyle}>
          <div
            className="close-pop-wrap"
            style={{ textAlign: "right", padding: "10px" }}
          >
            <img
              src={closex}
              alt="Close"
              onClick={() => setDescModal(false)}
              style={{ cursor: "pointer", width: "24px", height: "24px" }}
            />
          </div>

          <div
            style={{
              position: "sticky",
              top: "40px",
              zIndex: 1000,
              padding: "4px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => handlePayment({ ID: 1 })}
              className="buy-now-button"
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
              sx={{
                backgroundColor: "#e29c2e !important",
                pointerEvents: "auto",
              }}
            >
              Buy Now For{" "}
              {`$${selectedPgrm.amount} ${
                selectedPgrm.currency && selectedPgrm.currency.toUpperCase()
              }`}
            </Button>
          </div>

          <div
            style={{
              paddingTop: "70px",
              overflowY: "auto",
              maxHeight: "calc(100% - 130px)",
            }}
          >
            <Typography
              variant="h4"
              fontWeight={600}
              gutterBottom
              align="center"
            >
              HEALTH RESET CHALLENGE
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="https://japa.health/wp-content/uploads/2024/03/16330e6e-432b-48ae-96f0-51b5d50d1b22_Page_12_Image_0001-1024x576.jpg"
                alt="Health Reset Challenge"
                style={{
                  width: "50%",
                  height: "20%",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
              />
            </Box>

            <Typography variant="body1" paragraph align="center" sx={{ mt: 2 }}>
              <strong>💡 Benefits You Can Expect:</strong>
              <ul
                style={{ listStyleType: "none", paddingLeft: 0, color: "grey" }}
              >
                <li>🌙 Better Sleep</li>
                <li>⚡ Increased Energy</li>
                <li>😊 Improved Mood</li>
                <li>🏋️‍♀️ Better Body Composition</li>
                <li>🧠 Enhanced Focus</li>
                <li>🩸 Balanced Blood Sugar</li>
                <li>💪 Strengthened Immune Function</li>
                <li>🛌 Improved Recovery</li>
              </ul>
            </Typography>

            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={{ mb: 4, fontWeight: 600, mt: 4 }}
            >
              Meet Your Guides
            </Typography>

            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img
                    src={founder}
                    alt="Dr. Alexis Cowan"
                    style={{
                      width: "40%",
                      height: "auto",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                  <Typography variant="h6" mt={2}>
                    <strong>Dr. Alexis Cowan</strong>
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    fontSize={"12px"}
                    sx={{ textAlign: "justify" }}
                  >
                    Dr. Alexis Cowan is a Princeton-trained PhD in molecular
                    biology who operates at the interface of physiology,
                    metabolism, and nutrition. Here, she specializes in the
                    design and implementation of targeted dietary and lifestyle
                    protocols in areas including gut health, fat loss, metabolic
                    flexibility, and cognition. Dr. Cowan works with medical
                    professionals and coaches looking to expand their repertoire
                    of evidence-informed approaches, as well as high-performing
                    individuals seeking to optimize their health and
                    performance.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img
                    src={founder}
                    alt="Sheryl Utal"
                    style={{
                      width: "40%", // Image takes 40% of the width of the container
                      height: "auto", // Maintain aspect ratio
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                  <Typography variant="h6" mt={2}>
                    <strong>Sheryl Utal</strong>
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    fontSize={"12px"}
                    sx={{ textAlign: "justify" }}
                  >
                    Sheryl Utal is an entrepreneur, self-healer, and student of
                    life. Her passion for health optimization was ignited over
                    15 years ago after the standard medical model failed her in
                    the wake of an autoimmune disease diagnosis. This crisis
                    served as her initiation into the healing journey of a
                    lifetime, and guided her into deep practices across yoga,
                    breath work, sound healing, and meditation, and ultimately
                    to the cessation of autoimmunity. She has owned multiple
                    wellness businesses including Red Diamond Yoga and Japa
                    Health and has personally taught over 10,000 public classes.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Typography
              align="center"
              variant="h5"
              sx={{ mt: 3, fontWeight: 600, fontSize: "18px", mb: 3 }}
            >
              Are you ready to embrace the rejuvenating power of the sun and
              self regulation to unlock your full potential?
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              align="center"
              sx={{ mt: 2, fontWeight: 600, color: "#21686E" }}
            >
              Challenge Overview
            </Typography>
            <Typography
              variant="body1"
              paragraph
              align="center"
              sx={{
                background: "#f3f3f3",
                color: "gray",
                fontSize: "15px",
              }}
            >
              ✨ Personalized Wellness Assessment: Receive tailored suggestions
              to elevate your vitality.
              <br /> 📈 Score Your Progress: Track your journey with
              personalized scores to see results.
              <br />
              🌞 Emphasis on Circadian Health: Science-backed education on
              circadian biology.
              <br />
              💪 Learn Nervous System Regulation: Enhanced resilience and
              vitality
              <br />
              🧘‍♂️ Accountability & Support: Stay motivated with community, habit
              tracker, and group discussions.
              <br />
              🎁 Bonus Perks: Enjoy discounts on related products and continued
              support!
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height: "500px",
              }}
            >
              <img
                src="https://japa.health/wp-content/uploads/2024/03/16330e6e-432b-48ae-96f0-51b5d50d1b22_Page_12_Image_0001-1024x576.jpg"
                alt="Health Reset Challenge"
                style={{
                  width: "50%",
                  height: "20%",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
              />
            </Box>

            <Typography
              variant="h5"
              gutterBottom
              align="center"
              sx={{ mt: 4, fontWeight: 600 }}
            >
              You're Three Weeks Away From Elevated Well-Being
            </Typography>
            <Typography
              variant="body1"
              paragraph
              align="center"
              sx={{ fontSize: "14px", color: "gray" }}
            >
              {desc}
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              align="center"
              sx={{ mt: 4, mb: 4, fontWeight: 600 }}
            >
              Here's what we cover week by week
            </Typography>
            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" paragraph>
                  <strong>01</strong> After week 1 you will understand:
                  <ul
                    style={{
                      listStyleType: "square",
                      fontSize: "12px",
                      textAlign: "justify",
                    }}
                  >
                    <li>
                      What is the circadian rhythm the effect of different
                      wavelengths of light on the body and on the “master clock”
                      in the brain, which sets the rhythm for every cell in the
                      body{" "}
                    </li>
                    <li>
                      Why sunlight is an essential nutrient and vitamin D is a
                      biomarker
                    </li>
                    <li>
                      How sunglasses, contacts, prescription glasses, sunscreen,
                      and indoor living all contribute to the development of
                      dis-ease
                    </li>{" "}
                    <li>
                      {" "}
                      Why bright light and dark are equally important when it
                      comes to regulating the body’s essential functions{" "}
                    </li>{" "}
                    <li>
                      How to leverage light and dark to create health, reverse
                      dis-ease, and live life in accordance with the natural
                      rhythms of the planet{" "}
                    </li>{" "}
                    <li>
                      What is the nervous system and how self regulation, how
                      self awareness and emotions affect health so you can
                      consciosly manage your subtle bio-energetic state
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="center">
                  <img
                    src="https://japa.health/wp-content/uploads/2024/03/16330e6e-432b-48ae-96f0-51b5d50d1b22_Page_13_Image_0001-1024x576.jpg"
                    alt="Health Reset Challenge"
                    style={{
                      width: "90%",
                      height: "auto",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="center">
                  <img
                    src="https://japa.health/wp-content/uploads/2024/03/16330e6e-432b-48ae-96f0-51b5d50d1b22_Page_13_Image_0001-1024x576.jpg"
                    alt="Health Reset Challenge"
                    style={{
                      width: "90%",
                      height: "auto",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" paragraph>
                  <strong>02 </strong>
                  After week 2 you will understand:
                  <ul
                    style={{
                      listStyleType: "square",
                      fontSize: "12px",
                      textAlign: "justify",
                    }}
                  >
                    <li>
                      What is the circadian rhythm the effect of different
                      wavelengths of light on the body and on the “master clock”
                      in the brain, which sets the rhythm for every cell in the
                      body{" "}
                    </li>
                    <li>
                      Why sunlight is an essential nutrient and vitamin D is a
                      biomarker
                    </li>
                    <li>
                      How sunglasses, contacts, prescription glasses, sunscreen,
                      and indoor living all contribute to the development of
                      dis-ease
                    </li>{" "}
                    <li>
                      {" "}
                      Why bright light and dark are equally important when it
                      comes to regulating the body’s essential functions{" "}
                    </li>{" "}
                    <li>
                      How to leverage light and dark to create health, reverse
                      dis-ease, and live life in accordance with the natural
                      rhythms of the planet{" "}
                    </li>{" "}
                    <li>
                      What is the nervous system and how self regulation, how
                      self awareness and emotions affect health so you can
                      consciosly manage your subtle bio-energetic state
                    </li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" paragraph>
                  <strong>03</strong> After week 3 you will understand:
                  <ul
                    style={{
                      listStyleType: "square",
                      fontSize: "12px",
                      textAlign: "justify",
                      paddingRight: "5px",
                    }}
                  >
                    <li>
                      What is the circadian rhythm the effect of different
                      wavelengths of light on the body and on the “master clock”
                      in the brain, which sets the rhythm for every cell in the
                      body{" "}
                    </li>
                    <li>
                      Why sunlight is an essential nutrient and vitamin D is a
                      biomarker
                    </li>
                    <li>
                      How sunglasses, contacts, prescription glasses, sunscreen,
                      and indoor living all contribute to the development of
                      dis-ease
                    </li>{" "}
                    <li>
                      {" "}
                      Why bright light and dark are equally important when it
                      comes to regulating the body’s essential functions{" "}
                    </li>{" "}
                    <li>
                      How to leverage light and dark to create health, reverse
                      dis-ease, and live life in accordance with the natural
                      rhythms of the planet{" "}
                    </li>{" "}
                    <li>
                      What is the nervous system and how self regulation, how
                      self awareness and emotions affect health so you can
                      consciosly manage your subtle bio-energetic state
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="center">
                  <img
                    src="https://japa.health/wp-content/uploads/2024/03/16330e6e-432b-48ae-96f0-51b5d50d1b22_Page_13_Image_0001-1024x576.jpg"
                    alt="Health Reset Challenge"
                    style={{
                      width: "90%",
                      height: "auto",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Typography
              variant="h5"
              gutterBottom
              align="center"
              sx={{ mt: 5, mb: 5, fontWeight: 600 }}
            >
              What You Get:
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={8}>
                <Typography
                  variant="body1"
                  paragraph
                  style={{ fontSize: "12px !important", textAlign: "justify" }}
                >
                  💥 Japa Health Virtual Vitality Assessment – Value $69.99
                  <br />
                  💥 21 Day Circadian Health and Self Regulation Protocol –
                  Value $999
                  <br />
                  💥 Six 90 min lessons on self regulation and circadian biology
                  – Value $1500
                  <br />
                  💥 Gut Health Ebook by Dr. Alexis Cowan and Dr. Gabrielle Lyon
                  – Value $69.99
                  <br />
                  💥 Transcending Limits Shadow Work Ebook by Sheryl Utal –
                  Value $27
                  <br />
                  💥 Light Biology Interactions Video Lesson from Dr. Alexis –
                  Value $89 <br />
                  💥 Circadian Biology Video Lesson From Dr. Alexis – Value $89
                  <br />
                  💥 Badass Beginner Yoga Course with 13 classes by Sheryl Utal
                  – Value $27 <br />
                  💥 Breathwork Cheatsheet Ebook by Sheryl – Value $27
                  <br />
                  💥 Recommended products and exclusive discounts <br />
                  💥 3 Lessons from our 12 week Vibrant Life Method Program as
                  BONUS’s for you!
                  <ul sx={{ listStyleType: "square" }}>
                    <li>Exercise Science and Exercise Programming</li>{" "}
                    <li>
                      Environmental Toxins Lesson (Guest Lecturer: Mike
                      Maltierre)
                    </li>
                    <li>
                      Posture, Respiration, Pelvic Floor Health, and Gait (Guest
                      Lecturer: Rua Gilna)
                    </li>
                  </ul>
                  💥 Private Community <br />
                  💥 Ongoing support from Sheryl, Alexis, Your Challenge
                  Community and Japa Health
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box display="flex" justifyContent="center">
                  <img
                    src="https://japa.health/wp-content/uploads/2022/09/Screenshot-2.png"
                    alt="Result"
                    style={{
                      width: "100%",
                      height: "400px",
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Typography
              variant="h5"
              gutterBottom
              align="center"
              sx={{ mt: 2, fontWeight: 600, mb: 2 }}
            >
              What Our Students Say
            </Typography>
            <Typography variant="body1" paragraph align="center">
              <strong>This Reset over-delivered.</strong>
              <p
                style={{
                  fontSize: "14px",
                  color: "gray",
                  textAlign: "justify",
                }}
              >
                It undoubtedly exceeded my expectations. I'm still absorbing all
                the valuable information and generous extras you provided. Your
                insightful explanations have played a significant role in
                helping me adopt life-changing habits more consistently, from
                restructuring my day and lifestyle to prioritize sun exposure,
                grounding, and eliminating blue light to regularly checking in
                with my gut, caring for my nervous system, and so much more. The
                perfect blend of scientific knowledge, soulful wisdom, and
                support in your teaching truly resonates with me. I feel
                incredibly fortunate and grateful to have found such excellent
                teachers. I am looking forward to what's next!
              </p>
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              align="center"
              sx={{ fontWeight: 600, mt: 4, mb: 4 }}
            >
              Frequently Asked Questions
            </Typography>

            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ color: "#d01f1f", fontWeight: 600 }}>
                  Who can participate?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: "gray", fontWeight: 600 }}>
                  This is for anyone who wants to improve their well-being and
                  learn the science of health.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ color: "#d01f1f", fontWeight: 600 }}>
                  Do I need to buy anything extra for this?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: "gray", fontWeight: 600 }}>
                  No. You don’t but there are recommendations for some products
                  that can be helpful if you want. They are all optional and
                  very affordable if you are interested but it is NOT required.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ color: "#d01f1f", fontWeight: 600 }}>
                  Can I participate if I have limited availability?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: "gray", fontWeight: 600 }}>
                  Absolutely. This is not a time intensive process. There will
                  educational content you can consume at your own pace if you’d
                  like and you can do as much or as little as your schedule
                  allows. We encourage everyone to GO ALL IN to get the most
                  benefits but we understand if you want to start but need to
                  pace yourself don’t let that stop you!
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ color: "#d01f1f", fontWeight: 600 }}>
                  Is there a prize or award at the end of the challenge?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: "gray", fontWeight: 600 }}>
                  Yes, wisdom, experience and improved health! You will be
                  empowered to live the rest of your life in an elevated way and
                  you will have the tools to navigate life more efficiently so
                  much that you can inspire and teach your loved ones about it
                  too! Win Win!
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
      </Modal> */

/* <Modal open={payModal}>
        <Box sx={style} className="modal w-95">
          <div className="close-pop-wrap">
            <img
              src={closex}
              alt={closex}
              onClick={() => setPayModal(false)}
            ></img>
          </div>

          {props.user.paymentCheckout.url && (
            <iframe
              src={props.user.paymentCheckout.url}
              title="Payment"
              style={paymentForm}
              frameBorder="0"
            />
          )}
        </Box>
      </Modal> */
