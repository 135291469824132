import React, { useEffect, useState } from "react";
import "./header.scss";
import { connect } from "react-redux";
import headerImage from "../../../assets/images/header.png";
import LabelInput from "../../../atoms/label";
import ProgressBar from "../../../atoms/progressBar";
import { Box, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { getSurveyList } from "../../../redux/action/user";
import ButtonInput from "../../../atoms/button";
import WarningIcon from "@mui/icons-material/Warning";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "#fff",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Header = (props) => {
  const [progress, setProgress] = useState(0);
  const [answredGroup, setAnsworGroup] = useState(0);
  const [warningModal, setWarningModal] = useState({
    show: false,
    navigate: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    props.getSurveyList();
  }, []);

  useEffect(() => {
    if (props.user?.totalDetermine.length > 0) {
      const answedDetermin = props.user?.totalDetermine.filter(
        (obj) => obj.isFillUp === true
      ).length;
      if (props.user?.totalDetermine.length > answedDetermin) {
        const progrsss =
          ((answedDetermin + 1) / props.user?.totalDetermine.length) * 100;
        setAnsworGroup(answedDetermin + 1);
        setProgress(progrsss);
      } else {
        setAnsworGroup(props.user?.totalDetermine.length);
        setProgress(100);
      }
    }
  }, [props.user?.totalDetermine]);

  function signout() {
    localStorage.clear("userKey");
    localStorage.clear("_grecaptcha");
    sessionStorage.clear("_grecaptcha");
    // console.log(">>>>>>>>>>>>>>>>>Logging out user<<<<<<<<<<<<<<<<<<<<<");
    navigate("/signin");
    // alert(
    //   "Hi! Your session has expired due to inactivity. Please Sign in again to continue the assessment"
    // );
    window.location.reload();
  }

  return (
    <div className="progress-header-section">
      <div className="progress-header-section__fixed">
        <div className="gridContainer">
          <div className="inner-progress-header__fixed">
            <div>
              <img src={headerImage} alt={headerImage}></img>
            </div>
            <div className="cus-label progress-header-section__right-side">
              <div>
                {/* <img className='right-side-image' src={userThumb} alt={userThumb} ></img> */}
              </div>
              <div className="result-header-section__right-side--logout">
                <div
                  style={{ cursor: "pointer", paddingRight: "30px" }}
                  onClick={() =>
                    localStorage.getItem("review")
                      ? navigate("/plans")
                      : setWarningModal({ show: true, navigate: "/plans" })
                  }
                >
                  Plans & subscription
                </div>
              </div>
              {props.user?.surveyIdList &&
                props.user?.surveyIdList.length > 0 && (
                  <div className="result-header-section__right-side--logout">
                    <div
                      style={{ cursor: "pointer", paddingRight: "20px" }}
                      onClick={() =>
                        localStorage.getItem("review")
                          ? navigate("/history")
                          : setWarningModal({
                              show: true,
                              navigate: "/history",
                            })
                      }
                    >
                      Assessments
                    </div>
                  </div>
                )}
              <div className="result-header-section__right-side--logout">
                <div
                  style={{ cursor: "pointer", paddingRight: "30px" }}
                  onClick={() => signout()}
                >
                  Logout
                </div>
              </div>
              <div className="progress-header-section__right-side--userdetail">
                <div>Welcome</div>
                <div>{props.user.firstName}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {localStorage.getItem("review") === null && (
        <div className="progress-header-section__body-header">
          <div className="gridContainer">
            <div className="inner-progress-header__fixed--bottom">
              <h3 className="inner-progress-header__fixed--bottom--left">
                <LabelInput
                  text={props.user.language?.questionList?.title + " : "}
                  className={"header-title"}
                />
                <LabelInput
                  className={"header-title"}
                  text={props.user?.activeGroup || ""}
                />
              </h3>
              <div className="inner-progress-header__fixed--bottom--right">
                {props.user?.questionList?.length > 0 && (
                  <>
                    <div className="left-side-progress">
                      <ProgressBar progressValue={progress} />
                    </div>
                    <h4 style={{ display: "flex", marginLeft: "10px" }}>
                      <LabelInput
                        text={`${
                          answredGroup > 9 ? answredGroup : "0" + answredGroup
                        }`}
                      />
                    </h4>
                    <LabelInput
                      text={`/${
                        props.user?.totalDetermine?.length > 9
                          ? props.user?.totalDetermine?.length
                          : "0" + props.user?.totalDetermine?.length
                      } ${props.user.language?.questionList?.sections}`}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal open={warningModal.show}>
        <Box sx={style}>
          <div>
            <Box
              sx={{
                textAlign: "center",
                svg: {
                  height: "50px",
                  width: "50px",
                  path: {
                    fill: "#c90a0a",
                  },
                },
              }}
            >
              <WarningIcon />
            </Box>

            <Typography sx={{ textAlign: "justify" }}>
              You are about to leave this assessment, your progress will be
              saved for later. If you leave, you can navigate to ‘Assessments’
              and select ‘view’ to resume.
            </Typography>
            {/* <Typography>Do you wish to proceed ?</Typography> */}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                button: {
                  "&.MuiButtonBase-root": {
                    "&.MuiButton-root": {
                      padding: "5px 10px !important",
                      height: "unset !important",
                      borderRadius: "6px !important",
                      backgroundColor: "#faaa2f !important",
                      color: "#fff !important",
                      "&:nth-of-type(1)": {
                        mr: "10px !important",
                      },
                    },
                  },
                },
              }}
            >
              <ButtonInput
                // class="modal-button"
                text="Finish later"
                onSubmit={() =>
                  warningModal.navigate && navigate(warningModal.navigate)
                }
              />
              <ButtonInput
                // class="modal-button"
                text="Finish now"
                onSubmit={() => setWarningModal({ show: false, navigate: "" })}
              />
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

Header.propTypes = {};
Header.defaultProps = {};

const actionCreators = {
  getSurveyList,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(Header);
