// import io from 'socket.io-client';

// // Connect to the server (Replace 'http://localhost:3000' with your server URL)
// export const socket = io('http://localhost:7000/');


// export const api_key = 'http://172.16.1.106:7000/api/v1/'
// export const api_key = 'https://reasonably-sound-tortoise.ngrok-free.app/api/v1/'
export const api_key = 'https://devapi.japa.health/api/v1/'
// export const api_key = "http://localhost:7000/api/v1/";
// export const sns_api_key = 'http://172.16.1.106:7000/api/v1/'